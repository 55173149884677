import React from 'react';
import { Button } from 'react-bootstrap'; // Make sure you have react-bootstrap installed
import { HeartFill } from 'react-bootstrap-icons'; // Make sure you have react-bootstrap-icons installed
import '../InfoPage.css';

const About = () => {
  const pageStyle = {
    backgroundColor: '#202020',
    color: 'white',
    padding: '20px',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const textStyle = {
    maxWidth: '600px',
    textAlign: 'center',
  };

  const buttonStyle = {
    marginTop: '20px',
  };

  return (
    <div style={pageStyle}>
      <div style={textStyle}>
        <h1>About</h1>
        <p>
          Welcome to News Sources, the app that gives you easy access to sources referenced in popular news articles. Brought to you by the  <a id='newssourceLink' href='https://govglance.foundation/'>Gov Glance Foundation</a>
 
       
         
        </p>
        <Button
          id='standardButton'
          style={buttonStyle}
          onClick={(e) => {
            e.preventDefault();
            window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank");
          }}
        >
          <HeartFill id='donateIcon' /> Donate
        </Button>
      </div>
    </div>
  );
};

export default About;
