import React from 'react';
import Feed from './Feed';
import Footer from './Footer';
import '../Home.scss';
import NewsSourceNavbar from './NewsSourceNavbar';
import Privacy from './Privacy';
import BackButton from './BackButton';

const PrivacyPage = () => {
  return (
<>
<NewsSourceNavbar/>
<BackButton/>
<Privacy/>
<Footer/>
</>
  );
}

export default PrivacyPage;
