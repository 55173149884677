import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Button, Card, Spinner, Row, Col, Modal, Form, Toast } from 'react-bootstrap';
import { FileText, Newspaper, Clipboard, ClipboardCheckFill, Send, FolderPlus } from 'react-bootstrap-icons';
import '../Feed.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer';
import NewsSourceNavbar from './NewsSourceNavbar';
import ShareButton from './TestShareButton';

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
}

function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLinks, saveCollectionDate, saveCollectionDescription, saveCollectionNote, saveCollectionImg, saveCollectionCreator, saveCollectionPublisher) {
  let collectionCounter = localStorage.getItem('collectionNumberNewsSource') || 0;
  collectionCounter++;

  const itemKey = `newsSourceItem${collectionCounter}`;
  const itemData = {
    title: saveCollectionTitle,
    detailLink: saveCollectionDetailLink,
    docLinks: saveCollectionDocLinks,
    date: saveCollectionDate,
    description: saveCollectionDescription,
    note: saveCollectionNote,
    img: saveCollectionImg,
    creator: saveCollectionCreator,
    publisher: saveCollectionPublisher
  };

  localStorage.setItem(itemKey, JSON.stringify(itemData));
  localStorage.setItem('collectionNumberNewsSource', collectionCounter);

  // console.log('Saved item:', itemData);
}

function Article() {
  const { title } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false);
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [shareTitle, setShareTitle] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [note, setNote] = useState('');
  const [showToast, setShowToast] = useState(false); // State for toast visibility
  const [toastPosition, setToastPosition] = useState({ top: 0, left: 0 }); // Position of the toast

  const copyButtonRef = useRef(null); // Ref for the copy button

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch('https://evening-plains-21303.herokuapp.com/http://159.223.188.27:8000/articles/items/');

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        const parsedData = responseData.map(item => {
          const parsedSources = item.sources.map(source => {
            const match = source.match(/title:\s*"([^"]+)",\s*link:\s*"([^"]+)"/);
            return match ? { title: match[1], link: match[2] } : null;
          }).filter(source => source !== null);

          const parsedRelatedArticles = item.relatedArticles?.map(article => {
            const match = article.match(/title:\s*"([^"]+)",\s*link:\s*"([^"]+)"/);
            return match ? { title: match[1], link: match[2] } : null;
          }).filter(article => article !== null);

          return { ...item, sources: parsedSources, relatedArticles: parsedRelatedArticles || [] };
        });

        const articleData = parsedData.find(item => item.title.replace(/\s+/g, '-').toLowerCase() === title.toLowerCase());

        setArticle(articleData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [title]);

  const handleShare = () => {
    const urlTitle = title.replace(/\s+/g, '-');
    const shareableUrl = `${window.location.origin}/article/${urlTitle}`;
    setShareUrl(shareableUrl);
    setShareTitle(article.title);  // Set the share title
    setShareModalIsOpen(true);
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(shareUrl);
    setIsCopied(true); // Change the icon

    // Get the button position
    const buttonRect = copyButtonRef.current.getBoundingClientRect();
    setToastPosition({ top: buttonRect.top - 50, left: buttonRect.left + buttonRect.width / 2 });

    setShowToast(true); // Show the toast

    // Automatically hide the toast after 3 seconds
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  const handleSaveNote = () => {
    if (article) {
      saveNote(
        article.title,
        article.url,
        article.sources.length > 0 ? article.sources : [],
        formatDate(article.created_at),
        article.description,
        note,
        article.img && article.img.trim() !== "" ? article.img : "",
        article.creator,
        article.publisher
      );
      setCollectionModalIsOpen(false);
      setNote('');
    }
  };

  if (loading) {
    return <Spinner animation="grow" variant="light" />;
  }

  if (error) {
    return <p className="no-results-message">Error fetching data.</p>;
  }

  if (!article) {
    return <p className="no-results-message">No article found.</p>;
  }

  const link = article.url;
  const date = formatDate(article.created_at);

  return (
    <div id='backGround'>
      <NewsSourceNavbar />
      <Container id='newsItemContainer'>
        <Row>
          <Col xs={12} md={9}>
            <Card.Title id='titleText'>{article.title}</Card.Title>
            <Card.Body id='feedBodyText'>
              {article.creator && <div>By {article.creator}</div>}
            </Card.Body>
            <Card.Body id='feedBodyText'>{article.description}</Card.Body>
            <Card.Body id='feedBodyText'>
              {article.publisher && <div>{article.publisher}</div>}
              <div>{date}</div>
            </Card.Body>
            <div id="buttonContainer">
              {article.url && <Button id='articleButton' onClick={() => window.open(link, "_blank")}><Newspaper /> Article</Button>}
              {article.sources.map((source, sourceIndex) => (
                <Button key={sourceIndex} id='documentButton' onClick={() => window.open(source.link, "_blank")}><FileText /> {source.title}</Button>
              ))}
              <Button id='addToCollectionButton' onClick={() => setCollectionModalIsOpen(true)}><FolderPlus /> </Button>
              <Button id='documentButton' onClick={handleShare}><Send /> </Button>
            </div>
          </Col>
          {article.img && (
            <Col xs={12} md={3} className="d-flex align-items-center justify-content-center">
              <div style={{ width: '100%', height: '100%', overflow: 'hidden', borderRadius: '10px' }}>
                <Card.Img
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  src={article.img}
                  alt={article.title}
                />
              </div>
            </Col>
          )}
        </Row>
      </Container>
      <Footer />

      <Modal id='shareModal' show={shareModalIsOpen} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setShareModalIsOpen(false)}>
        <Modal.Header id='newsModalHeader' closeButton>
          Share
        </Modal.Header>
        <Modal.Body id='newsModalBody'>
          <Form.Group id="noteModalNewsItem">
            <Form.Control id="noteModalNewsItem" type="text" readOnly value={shareUrl} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer id='noteModalNewsItemFooter'>
          <Button variant="primary" id='addToCollectionButton' onClick={handleCopyUrl} className="ml-auto" ref={copyButtonRef}>
            {isCopied ? <ClipboardCheckFill /> : <Clipboard />} 
          </Button>
          <ShareButton title={shareTitle} url={shareUrl} />
        </Modal.Footer>
      </Modal>

      <Modal id='newsModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
        <Modal.Header id='newsModalHeader' closeButton>
          Add to Collection
        </Modal.Header>
        <Modal.Body id='newsModalBody'>
          <div id='backGround'>
            {article && (
              <>
                {article.title && <p><strong>Title:</strong> {article.title}</p>}
                {article.created_at && <p><strong>Date:</strong> {formatDate(article.created_at)}</p>}
                {article.description && <p><strong>Description:</strong> {article.description}</p>}
              </>
            )}
            <Form id='noteModalForm'>
              <Form.Group className="mb-3" controlId="noteModalNewsItem">
                <Form.Control
                  style={{ color: 'white' }}
                  id="noteModalNewsItem"
                  placeholder="Add a note here"
                  as="textarea"
                  rows={3}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer id='noteModalNewsItemFooter'>
          <Button variant="primary" id='noteSaveToCollectionButton' onClick={handleSaveNote}>
            Save To Collection
          </Button>
        </Modal.Footer>
      </Modal>

      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        autohide
        delay={3000}
        style={{
          backgroundColor: '#202020',
          color: 'white',
          position: 'absolute',
          top: toastPosition.top,
          left: toastPosition.left,
          transform: 'translateX(-50%)',
          zIndex: 1060,
          padding: '5px 10px',
          maxWidth: '150px',
          textAlign: 'center',
        }}
      >
        <Toast.Body>Copied URL!</Toast.Body>
      </Toast>
    </div>
  );
}

export default Article;
