import React from 'react';
import { Button } from 'react-bootstrap';
import { BoxArrowUp } from 'react-bootstrap-icons';

const ShareButton = ({ title, url }) => {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: 'Here is an interesting article.',
          url: url,
        });
        console.log('Content shared successfully');
      } catch (error) {
        console.error('Error sharing content: ', error);
      }
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  };

  return (
    <Button id='addToCollectionButton' onClick={handleShare}>
      <BoxArrowUp/>
    </Button>
  );
};

export default ShareButton;
