// BackButton.js
import React, { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import '../BackButton.css'; // Ensure this path is correct

const BackButton = () => {
    const navigate = useNavigate();
    const [hasVisited, setHasVisited] = useState(false);

    useEffect(() => {
        const visited = localStorage.getItem('hasVisited');
        if (visited) {
            setHasVisited(true);
        } else {
            localStorage.setItem('hasVisited', 'true');
        }
    }, []);

    const handleBackClick = () => {
        navigate(-1);
    };

    if (!hasVisited) return null;

    return (
        <Button id='standardButton' className="floating-back-button" onClick={handleBackClick}>
            <ArrowLeft />
        </Button>
    );
};

export default BackButton;
