import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Feed.css';

const RefreshPrompt = () => {
  const [showModal, setShowModal] = useState(false);
  let inactivityTimeout;

  const startInactivityTimer = () => {
    clearTimeout(inactivityTimeout);
    inactivityTimeout = setTimeout(() => {
      setShowModal(true);
    }, 5 * 60 * 1000); // 5 minutes
  };

  useEffect(() => {
    const handleUserActivity = () => {
      startInactivityTimer();
    };

    startInactivityTimer();

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      clearTimeout(inactivityTimeout);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal id='shareModal' show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header id='newsModalHeader' closeButton>
          Refresh for updates!
        </Modal.Header>
        <Modal.Body id='newsModalBody'>
          <p>We noticed you've been inactive, refresh the page for updates!</p>
        </Modal.Body>
        <Modal.Footer id='noteModalNewsItemFooter'>
          <Button id='standardButton' onClick={handleRefresh}>
            Refresh Page
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RefreshPrompt;
