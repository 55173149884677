import React from 'react';
import Footer from './Footer';
import '../Home.scss';
import Collection from './Collection';
import CollectionNavbar from './CollectionNavbar';
import BackButton from './BackButton';

const CollectionPage = () => {
  return (
<>
<div id='backGround'>
<CollectionNavbar/>
<BackButton/>
<Collection/>

<Footer/>
</div>
</>
  );
}

export default CollectionPage;
