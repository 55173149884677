import React from 'react';

import Footer from './Footer';
import '../Home.scss';
import NewsSourceNavbar from './NewsSourceNavbar';
import Privacy from './Privacy';
import About from './About';
import BackButton from './BackButton';

const AboutPage = () => {
  return (
<>
<NewsSourceNavbar/>
<BackButton/>
<About/>
<Footer/>
</>
  );
}

export default AboutPage;
