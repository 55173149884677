// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Image, Nav, Navbar, Dropdown } from 'react-bootstrap';
// import { Collection, House, List } from 'react-bootstrap-icons';
// import '../Navbar.css';

// const NewsSourceNavbar = () => {
//     return (
//         <Navbar id='navbar'>
//             <div id='realNavbarTop' className="w-100 d-flex justify-content-between align-items-center">
//                 <Navbar.Brand onClick={(e) => {
//                                 e.preventDefault();
//                                 window.open('/', "_self")
//                             }} className="flex-shrink-0 d-flex align-items-center">
//                     <Image height='40px' id='navbarLogo' width='80px' src='link-2.svg'></Image>
//                 </Navbar.Brand>
//                 <div id='navbarHeading' className="flex-grow-1 text-center d-flex align-items-center justify-content-center">
//                     <h3 onClick={(e) => {
//                                 e.preventDefault();
//                                 window.open('/', "_self")
//                             }} id='branchesHeadingUS' className="m-0">News Sources</h3>
//                 </div>
//                 <Nav className="flex-shrink-0 d-flex align-items-center">
//                     <Dropdown align="end">
//                         <Dropdown.Toggle id="standardButton" variant="secondary">
//                             <List />
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu id='navMenu'>
//                             <Dropdown.Item id='navMenuItem' href="/" onClick={(e) => {
//                                 e.preventDefault();
//                                 window.open('/', "_self")
//                             }}>Home</Dropdown.Item>
//                             <Dropdown.Item id='navMenuItem' href="/about" onClick={(e) => {
//                                 e.preventDefault();
//                                 window.open('/collection', "_self")
//                             }}> Collection</Dropdown.Item>
//                                 <Dropdown.Item id='navMenuItem' href="/about" onClick={(e) => {
//                                 e.preventDefault();
//                                 window.open('/about', "_self")
//                             }}> About</Dropdown.Item>
//                                        <Dropdown.Item id='navMenuItem' href="/about" onClick={(e) => {
//                                 e.preventDefault();
//                                 window.open('/privacy', "_self")
//                             }}> Privacy</Dropdown.Item>
                            

                          
//                         </Dropdown.Menu>
//                     </Dropdown>
//                 </Nav>
//             </div>
//         </Navbar>
//     );
// }

// export default NewsSourceNavbar;



import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Image, Nav, Navbar, Dropdown } from 'react-bootstrap';
import { Collection, House, Info, InfoCircle, List, Lock } from 'react-bootstrap-icons';
import '../Navbar.css';

const CollectionNavbar = () => {
    return (
        <>
        <div id='spacer'>
        <div id='backGround'>
        <Container className="navbar-container">
            <Navbar id='navbar'>
                <div id='realNavbarTop' className="w-100 d-flex justify-content-between align-items-center">
                    <Navbar.Brand onClick={(e) => {
                        e.preventDefault();
                        window.open('/', "_self")
                    }} className="flex-shrink-0 d-flex align-items-center">
                        <Image height='40px' id='navbarLogo' width='80px' src='link-2.svg'></Image>
                    </Navbar.Brand>
                    <div id='navbarHeading' className="flex-grow-1 text-center d-flex align-items-center justify-content-center">
                        <h3 onClick={(e) => {
                            e.preventDefault();
                            window.open('/', "_self")
                        }} id='branchesHeadingUS' className="m-0">Collection</h3>
                    </div>
                    <Nav className="flex-shrink-0 d-flex align-items-center">
                        <Dropdown align="end">
                            <Dropdown.Toggle id="standardButton" variant="secondary">
                                <List />
                            </Dropdown.Toggle>
                            <Dropdown.Menu id='navMenu'>
                                <Dropdown.Item id='navMenuItem' href="/" onClick={(e) => {
                                    e.preventDefault();
                                    window.open('/', "_self")
                                }}><House></House>Home</Dropdown.Item>
                                <Dropdown.Item id='navMenuItem' href="/collection" onClick={(e) => {
                                    e.preventDefault();
                                    window.open('/collection', "_self")
                                }}><Collection/> Collection</Dropdown.Item>
                                <Dropdown.Item id='navMenuItem' href="/about" onClick={(e) => {
                                    e.preventDefault();
                                    window.open('/about', "_self")
                                }}><InfoCircle/> About</Dropdown.Item>
                                <Dropdown.Item id='navMenuItem' href="/privacy" onClick={(e) => {
                                    e.preventDefault();
                                    window.open('/privacy', "_self")
                                }}><Lock/> Privacy</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </div>
            </Navbar>
        </Container>
        </div>
        </div>
        </>
    );
}

export default CollectionNavbar;
