import React, { useState, useEffect, useRef } from 'react';
import { Container, Button, Modal, Card, Spinner, FormControl, Form, Row, Col, Toast } from 'react-bootstrap';
import { FileText, FolderPlus, Newspaper, Clipboard, ClipboardCheckFill, Send } from 'react-bootstrap-icons';
import '../Feed.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ShareButton from './TestShareButton';

const openLink = (link) => {
  try {
    // Ensure the link is properly formatted
    let formattedLink = link;
    if (link.startsWith('capacitor://localhost')) {
      // Replace 'capacitor://localhost' with 'http://localhost' for the simulator
      formattedLink = link.replace('capacitor://localhost', 'http://localhost');
    } else if (!link.startsWith('http://') && !link.startsWith('https://')) {
      // Add 'http://' if the link does not already have 'http://' or 'https://'
      formattedLink = `http://${link}`;
    }
    console.log('Opening link:', formattedLink);
    window.open(formattedLink, '_blank');
  } catch (error) {
    console.error('Failed to open URL:', error);
  }
};

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
}

function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLinks, saveCollectionDate, saveCollectionDescription, saveCollectionNote, saveCollectionImg, saveCollectionCreator, saveCollectionPublisher) {
  let collectionCounter = localStorage.getItem('collectionNumberNewsSource') || 0;
  collectionCounter++;

  const itemKey = `newsSourceItem${collectionCounter}`;
  const itemData = {
    title: saveCollectionTitle,
    detailLink: saveCollectionDetailLink,
    docLinks: saveCollectionDocLinks,
    date: saveCollectionDate,
    description: saveCollectionDescription,
    note: saveCollectionNote,
    img: saveCollectionImg,
    creator: saveCollectionCreator,
    publisher: saveCollectionPublisher
  };

  localStorage.setItem(itemKey, JSON.stringify(itemData));
  localStorage.setItem('collectionNumberNewsSource', collectionCounter);

  // console.log('Saved item:', itemData);
}

function Feed() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [note, setNote] = useState('');
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [shareTitle, setShareTitle] = useState('');
  const [isCopied, setIsCopied] = useState(false); // State for icon change
  const [showToast, setShowToast] = useState(false); // State for toast visibility
  const [toastPosition, setToastPosition] = useState({ top: 0, left: 0 }); // Position of the toast

  const copyButtonRef = useRef(null); // Ref for the copy button

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch('https://evening-plains-21303.herokuapp.com/http://api.newssources.org/articles/items/');

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        //console.log('Fetched data:', responseData);

        const parsedData = responseData.map(item => {
          const parsedSources = item.sources.map(source => {
            const match = source.match(/title:\s*"([^"]+)",\s*link:\s*"([^"]+)"/);
            return match ? { title: match[1], link: match[2] } : null;
          }).filter(source => source !== null);

          const parsedRelatedArticles = item.relatedArticles?.map(article => {
            const match = article.match(/title:\s*"([^"]+)",\s*link:\s*"([^"]+)"/);
            return match ? { title: match[1], link: match[2] } : null;
          }).filter(article => article !== null);

          return { ...item, sources: parsedSources, relatedArticles: parsedRelatedArticles || [] };
        });

        setData(parsedData);
        setSearchResults(parsedData);
        setLoading(false);

        // Save the data to local storage
        localStorage.setItem('cachedData', JSON.stringify(parsedData));
        localStorage.setItem('cacheTimestamp', Date.now());
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);

        // Try to load data from local storage
        const cachedData = localStorage.getItem('cachedData');
        if (cachedData) {
          const parsedData = JSON.parse(cachedData);
          setData(parsedData);
          setSearchResults(parsedData);
        }

        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults(data);
    } else {
      const filteredData = data.filter(item => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const titleMatch = item.title?.toLowerCase().includes(lowerCaseSearchTerm);
        const sourcesMatch = item.sources.some(source => source.title.toLowerCase().includes(lowerCaseSearchTerm));
        return titleMatch || sourcesMatch;
      });
      setSearchResults(filteredData);
    }
  }, [searchTerm, data]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSaveNote = () => {
    if (selectedItem) {
      saveNote(
        selectedItem.title,
        selectedItem.url,
        selectedItem.sources.length > 0 ? selectedItem.sources : [],
        formatDate(selectedItem.created_at),
        selectedItem.description,
        note,
        selectedItem.img && selectedItem.img.trim() !== "" ? selectedItem.img : "",
        selectedItem.creator,
        selectedItem.publisher
      );
      setCollectionModalIsOpen(false);
      setNote('');
    }
  };

  const handleShare = (item) => {
    const urlTitle = item.title.replace(/\s+/g, '-');
    const shareableUrl = `${window.location.origin}/article/${urlTitle}`;
    setShareUrl(shareableUrl);
    setShareTitle(item.title);
    setShareModalIsOpen(true);
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(shareUrl);
    setIsCopied(true); // Change the icon

    // Get the button position
    const buttonRect = copyButtonRef.current.getBoundingClientRect();
    setToastPosition({ top: buttonRect.top - 50, left: buttonRect.left + buttonRect.width / 2 });

    setShowToast(true); // Show the toast

    // Automatically hide the toast after 3 seconds
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  const handleCloseShareModal = () => {
    setShareModalIsOpen(false);
    setShowToast(false);
  };

  const renderData = () => {
    if (loading) {
      return <Spinner animation="grow" variant="light" />;
    }

    if (error && !data.length) {
      return <div id='backGround'><p className="no-results-message">Error fetching data.</p></div>;
    }

    if (!searchResults.length) {
      return <p className="no-results-message">No results found. Please try a different search term.</p>;
    }

    const filteredData = searchResults.filter(item => item.sources.length > 0);

    if (!filteredData.length) {
      return <p className="no-results-message">No results found with non-empty sources.</p>;
    }

    return filteredData.map((item, index) => {
      const urlTitle = item.title.replace(/\s+/g, '-');
      const shareableUrl = `${window.location.origin}/article/${urlTitle}`;
      const link = item.url;
      const date = formatDate(item.created_at);
      return (
        <div id='backGround' key={index}>
          <div id='feedSpacing'>
            <Container id='newsItemContainer'>
              <Row>
                <Col xs={12} md={9}>
                  <Card.Title onClick={() => openLink(shareableUrl)} id='titleText'>{item.title}</Card.Title>
                  <Card.Body id='feedBodyText'>
                    {item.creator && <div>By {item.creator}</div>}
                  </Card.Body>
                  <Card.Body id='feedBodyText'>{item.description}</Card.Body>
                  <Card.Body id='feedBodyText'>
                    {item.publisher && <div>{item.publisher}</div>}
                    <div>{date}</div>
                  </Card.Body>
                  <div id="buttonContainer">
                    {item.url && <Button id='articleButton' onClick={() => openLink(link)}><Newspaper /> Article</Button>}
                    {item.sources.map((source, sourceIndex) => (
                      <Button key={sourceIndex} id='documentButton' onClick={() => openLink(source.link)}><FileText /> {source.title}</Button>
                    ))}
                    <Button id='addToCollectionButton' onClick={() => {
                      setSelectedItem(item);
                      setCollectionModalIsOpen(true);
                    }}><FolderPlus /></Button>
                    <Button id='documentButton' onClick={() => handleShare(item)}><Send /></Button>
                  </div>
                  {item.relatedArticles && item.relatedArticles.length > 0 && (
                    <>
                      <Card.Body id='feedBodyText'><strong>Related Articles:</strong></Card.Body>
                      <div id="buttonContainer">
                        {item.relatedArticles.map((article, articleIndex) => (
                          <Button key={articleIndex} id='relatedArticleButton' onClick={() => openLink(article.link)}><Newspaper /> {article.title}</Button>
                        ))}
                      </div>
                    </>
                  )}
                </Col>
                {item.img && (
                  <Col xs={12} md={3} className="d-flex align-items-center justify-content-center">
                    <div style={{ width: '100%', height: '100%', overflow: 'hidden', borderRadius: '10px' }}>
                      <Card.Img
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        src={item.img}
                        alt={item.title}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </Container>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div id='backGround'>
  
        <Container>
          <div id='search' className="search-bar">
            <Form className="w-100" onSubmit={(e) => { e.preventDefault(); }}>
              <Form.Group className="d-flex justify-content-center align-items-center w-100">
                <FormControl
                  type="text"
                  placeholder="Search..."
                  className="mr-sm-2 custom-search-input"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  id="searchInput"
                />
              </Form.Group>
            </Form>
          </div>
          {renderData()}
          <Modal id='newsModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
            <Modal.Header id='newsModalHeader' closeButton>
              Add to Collection
            </Modal.Header>
            <Modal.Body id='newsModalBody'>
              <div id='backGround'>
                {selectedItem && (
                  <>
                    {selectedItem.title && <p><strong>Title:</strong> {selectedItem.title}</p>}
                    {selectedItem.created_at && <p><strong>Date:</strong> {formatDate(selectedItem.created_at)}</p>}
                    {selectedItem.description && <p><strong>Description:</strong> {selectedItem.description}</p>}
                  </>
                )}
                <Form id='noteModalForm'>
                  <Form.Group className="mb-3" controlId="noteModalNewsItem">
                    <Form.Control
                      style={{ color: 'white' }}
                      id="noteModalNewsItem"
                      placeholder="Add a note here"
                      as="textarea"
                      rows={3}
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </div>
            </Modal.Body>
            <Modal.Footer id='noteModalNewsItemFooter'>
              <Button variant="primary" id='noteSaveToCollectionButton' onClick={handleSaveNote}>
                Save To Collection
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal id='shareModal' show={shareModalIsOpen} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseShareModal}>
            <Modal.Header id='newsModalHeader' closeButton>
              Share
            </Modal.Header>
            <Modal.Body id='newsModalBody'>
              <Form.Group id="noteModalNewsItem">
                <Form.Control id="noteModalNewsItem" type="text" readOnly value={shareUrl} />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer id='noteModalNewsItemFooter'>
              <Button id='addToCollectionButton' onClick={handleCopyUrl} className="ml-auto" ref={copyButtonRef}>
                {isCopied ? <ClipboardCheckFill /> : <Clipboard />} 
              </Button>
              <ShareButton title={shareTitle} url={shareUrl} />
            </Modal.Footer>
          </Modal>
        </Container>
        <Toast
          show={showToast}
          onClose={() => setShowToast(false)}
          autohide
          delay={3000}
          style={{
            backgroundColor: '#202020',
            color: 'white',
            position: 'absolute',
            top: toastPosition.top,
            left: toastPosition.left,
            transform: 'translateX(-50%)', // Center the toast horizontally above the button
            zIndex: 1060,
            padding: '5px 10px', // Adjust padding for smaller size
            maxWidth: '150px', // Set a max width to ensure the toast is not too wide
            textAlign: 'center', // Center the text within the toast
          }}
        >
          <Toast.Body>Copied URL!</Toast.Body>
        </Toast>
      </div>
    </>
  );
}

export default Feed;
