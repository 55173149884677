import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Feed from './Feed';
import Footer from './Footer';
import NewsSourceNavbar from './NewsSourceNavbar';
import { HeartFill } from 'react-bootstrap-icons';
import '../Home.scss';
import RefreshPrompt from './RefreshPrompt';
import BackButton from './BackButton';

const Home = () => {
  const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   const isFirstVisit = localStorage.getItem('isFirstVisit');
  //   if (!isFirstVisit) {
  //     setShowModal(true);
  //     localStorage.setItem('isFirstVisit', 'true');
  //   }
  // }, []);

  useEffect(() => {
    requestNotificationPermission();
  }, []);

  const handleClose = () => setShowModal(false);

  function requestNotificationPermission() {
    if ('Notification' in window) {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          showNotification('Hello!', {
            body: 'This is a test notification.',
            icon: '/favicon.ico' // Optional icon
          });
        } else if (permission === 'denied') {
          console.log('Notification permission denied.');
        }
      });
    }
  }

  function showNotification(title, options) {
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        new Notification(title, options);
      }
    }
  }

  return (
    <>
      <div id='backGround'>
        <NewsSourceNavbar />
        <BackButton/>
<RefreshPrompt/>
        <Feed />
        <Footer />
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header id='homeModal' closeButton>
          <Modal.Title>Welcome to News Sources</Modal.Title>
        </Modal.Header>
        <Modal.Body id='homeModal'>
          <p>
            Welcome to News Sources, the app that gives you easy access to sources referenced in popular news articles. Brought to you by the Gov Glance Foundation.
          </p>
          <Button
            id='standardButton'
            onClick={(e) => {
              e.preventDefault();
              window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank");
            }}
          >
            <HeartFill id='donateIcon' /> Donate
          </Button>
        </Modal.Body>
        <Modal.Footer id='homeModal'>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Home;
