import React from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { HeartFill, InfoCircle, Lock } from 'react-bootstrap-icons';
import '../Footer.scss';

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <Navbar>
          <Container id='footerNav'>
            <Nav className="mx-auto" variant='pills'>
              <Nav.Item>
                <Nav.Link id='footerContent'>News Sources by Gov Glance Foundation Inc. 501(c)(3)</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link id='standardButton' onClick={(e) => {
                  e.preventDefault();
                  window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                }}>
                  <HeartFill id='donateIcon'/> Donate
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link id='standardButton'  onClick={(e) => {
                  e.preventDefault();
                  window.open('/about', "_self");
                }}><InfoCircle/>About</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link id='standardButton'  onClick={(e) => {
                  e.preventDefault();
                  window.open('/privacy', "_self");
                }}><Lock/>Privacy</Nav.Link>
              </Nav.Item>
            </Nav>
          </Container>
        </Navbar>
      </footer>
    </div>
  );
}

export default Footer;
