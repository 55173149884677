import React, { useState, useEffect } from 'react';
import { Container, Button, Card, Row, Col, Spinner, Form, Modal } from 'react-bootstrap';
import { FileText, Newspaper, X } from 'react-bootstrap-icons';
import '../Feed.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  const day = utcDate.getDate();
  const month = utcDate.getMonth() + 1;
  const year = utcDate.getFullYear();

  return `${month}-${day}-${year}`;
}

function Collection() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editedNote, setEditedNote] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [noteModalIsOpen, setNoteModalIsOpen] = useState(false);

  useEffect(() => {
    const collectionNumber = localStorage.getItem('collectionNumberNewsSource') || 0;
    const items = [];

    for (let i = 1; i <= collectionNumber; i++) {
      const itemData = localStorage.getItem(`newsSourceItem${i}`);
      if (itemData) {
        const item = JSON.parse(itemData);
        item.id = i; // Add id for each item
        items.push(item);
      }
    }

    setData(items);
    setLoading(false);
  }, []);

  const handleDelete = (id) => {
    // Remove the item from localStorage
    localStorage.removeItem(`newsSourceItem${id}`);

    // Update the collection number
    const collectionNumber = localStorage.getItem('collectionNumberNewsSource');
    if (collectionNumber > 0) {
      localStorage.setItem('collectionNumberNewsSource', collectionNumber - 1);
    }

    // Remove the item from the state
    setData(data.filter(item => item.id !== id));
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setEditedNote(data[index].note);
    setNoteModalIsOpen(true);
  };

  const handleSaveEdit = (id, index) => {
    // Update the note in localStorage
    const itemData = JSON.parse(localStorage.getItem(`newsSourceItem${id}`));
    itemData.note = editedNote;
    localStorage.setItem(`newsSourceItem${id}`, JSON.stringify(itemData));

    // Update the note in the state
    const updatedData = [...data];
    updatedData[index].note = editedNote;
    setData(updatedData);

    // Reset the edit state
    setEditIndex(null);
    setEditedNote('');
    setNoteModalIsOpen(false);
  };

  const renderData = () => {
    if (loading) {
      return <Spinner animation="grow" variant="light" />;
    }

    if (!data || data.length === 0) {
      return <p className="no-results-message">No items in the collection.</p>;
    }

    return data.map((item, index) => {
      const date = formatDate(item.date);
      return (
        <div id='backGround' key={index}>
          <div id='feedSpacing'>
            <Container id='newsItemContainer' style={{ position: 'relative' }}>
              <Button id='standardButton' style={{ position: 'absolute', top: '10px', right: '10px', padding: 0, border: 'none', background: 'none', color: 'white' }} onClick={() => handleDelete(item.id)}><X size={30} /></Button>
              <Row>
                <Col xs={12} md={9}>
                  {item.title && <Card.Title id='titleText' style={{ paddingRight: '40px' }}>{item.title}</Card.Title>}
               
               
                  {item.creator && (
                    <Card.Body id='feedBodyText'>
                      <div>By {item.creator}</div>
                    </Card.Body>
                  )}
                     {item.description && <Card.Body id='feedBodyText'>{item.description}</Card.Body>}
                  {item.publisher && (
                    <Card.Body id='feedBodyText'>
                      <div>{item.publisher}</div>
                    </Card.Body>
                  )}
                     {item.date && (
                    <Card.Body id='feedBodyText'>
                      <div>{date}</div>
                    </Card.Body>
                  )}
                  {item.note && (
                    <Card.Body id='feedBodyText'>
                      <div>{item.note}</div>
                    </Card.Body>
                  )}
                  <div id="buttonContainer">
                    {item.detailLink && <Button id='standardButton' onClick={() => window.open(item.detailLink, "_blank")}><Newspaper /> Article</Button>}
                    {item.docLinks && item.docLinks.map((source, sourceIndex) => (
                      <Button key={sourceIndex} id='standardButton' onClick={() => window.open(source.link, "_blank")}><FileText /> {source.title}</Button>
                    ))}
                    <Button id='standardButton' onClick={() => handleEdit(index)}>Edit Note</Button>
                  </div>
                </Col>
                {item.img && (
                  <Col xs={12} md={3} className="d-flex align-items-center justify-content-center">
                    <div style={{ width: '100%' }}>
                      <Card.Img style={{ borderRadius: '10px' }} src={item.img} alt={item.title} />
                    </div>
                  </Col>
                )}
              </Row>
            </Container>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div id='backGround'>
        {renderData()}
      </div>

      <Modal id='newsModal' show={noteModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNoteModalIsOpen(false)}>
        <Modal.Header id='newsModalHeader' closeButton>
          Edit Note
        </Modal.Header>
        <Modal.Body id='newsModalBody'>
          <div id='backGround'>
            <Form id='noteModalForm'>
              <Form.Group className="mb-3" controlId="editNote">
                <Form.Control
                  id="noteModalNewsItem"
                  as="textarea"
                  rows={3}
                  value={editedNote}
                  onChange={(e) => setEditedNote(e.target.value)}
                  style={{ color: 'white' }}
                  placeholder="Edit your note here"
                />
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer id='noteModalFooter'>
          <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => handleSaveEdit(data[editIndex].id, editIndex)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Collection;
